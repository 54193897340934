import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { Helmet } from 'react-helmet-async';
import { useParams, withRouter } from 'react-router-dom';

import { getPageTypeBySlug } from 'lib/contentful-client';

import AdminPlaceholder from 'components/consumer/AdminPlaceholder';
import Nav from './components/Nav/Nav';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const BlogHome = loadable(() => import('./BlogHome'));
const BlogCategory = loadable(() => import('./BlogCategory'));
const SinglePost = loadable(() => import('./SinglePost'));

const Home = ({ match, history, staticContext, isAdmin }) => {
  const params = useParams();
  const slug = !params.slug ? '' : params.slug;
  const blogUrl = `${match.url.split('/')[1].replace(/\/$/, '')}/`;
  const currentPage = !slug ? 'home' : getPageTypeBySlug(slug);

  if (isAdmin) {
    return (
      <AdminPlaceholder>
        JB Contentful API Blog Component Placeholder for Variant Builder. <br />
        The component will display correctly in Consumer
      </AdminPlaceholder>
    );
  }

  return (
    <div className="[&_a]:no-underline">
      <Helmet>
        <title>Joybird | A Design Blog by Joybird</title>

        <meta
          name="description"
          content="A blog about interior design, home decor inspiration and more. Express yourself through your space."
        />

        <meta property="og:title" content="Canvas — A Blog by Joybird" />

        <meta
          property="og:description"
          content="A blog about interior design, home decor inspiration and more. Express yourself through your space."
        />

        <meta property="og:type" content="website" />
      </Helmet>

      <ScrollToTop />
      <Nav blogUrl={blogUrl} slug={slug} />
      {currentPage === 'home' && <BlogHome slug={slug} blogUrl={blogUrl} />}
      {currentPage === 'category' && (
        <BlogCategory blogUrl={blogUrl} slug={slug} />
      )}
      {currentPage === 'single' && (
        <SinglePost
          blogUrl={blogUrl}
          slug={slug}
          history={history}
          staticContext={staticContext}
        />
      )}
    </div>
  );
};

Home.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
  history: PropTypes.objectOf(PropTypes.object),
  staticContext: PropTypes.objectOf(PropTypes.object),
  isAdmin: PropTypes.bool,
};

export default withRouter(Home);
